* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(7, 42, 70, 0.3);
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #072a46;
  border: 0.5px solid rgb(242, 242, 242);
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100vw;
  height: 100vh;
  min-height: 500px;
}

main > div {
  width: 100%;
  height: 100%;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ================================================================================================

Layout Section

================================================================================================ */
.carousel-inner {
  height: 100%;
}

.main-wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  pointer-events: none;
}

.overlay,
.form-panel.one:before {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.outlet {
  position: relative;
  background-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(../public/images/chapel.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  max-width: 99vw;
  height: auto;
  max-height: 75vh;
  border-radius: 5px 0 5px 0;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  margin: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: height 0.3s ease-in-out;
}

.outlet:has(.overflow-y, .confirm-form) {
  overflow-y: auto;
}

.form-toggle {
  position: absolute;
  top: 30px;
  right: 80px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform-origin: center;
  transform: translate(0, -25%) scale(0);
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-toggle:before,
.form-toggle:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  background: #072a46;
  transform: translate(-50%, -50%);
}

.form-toggle:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.form-toggle:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.form-toggle.visible {
  transform: translate(0, -25%) scale(1);
  opacity: 1;
}

/* ================================================================================================

Forms / Inputs Section

================================================================================================ */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 20px;
  position: relative;
  color: #072a46;
}

.form-group:last-child {
  margin: 0;
}

.form-group label {
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
  cursor: pointer;
}

.two .form-group label {
  color: #ffffff;
}

.form-group:has(input:focus, input:active, select:focus, select:active) label {
  color: #072a46;
  margin: 0 0 5px;
}

.two
  .form-group:has(input:focus, input:active, select:focus, select:active)
  label {
  color: white;
}

.form-group input,
.form-group select {
  outline: none;
  display: block;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  transition: 0.3s ease;
}

input:disabled,
select:disabled {
  cursor: progress;
}

.matric-input,
input[name="matric"] {
  text-transform: uppercase;
}

.form-group:has(.password-eye) input {
  padding-right: 35px;
}

.form-group .password-eye {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
}

.form-group select,
.form-group input[type="date"],
.form-group input[type="time"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.form-group .select-icon {
  position: absolute;
  right: 15px;
  bottom: 15px;
  pointer-events: none;
  color: white;
}

::placeholder {
  text-transform: initial;
  color: inherit;
  letter-spacing: initial;
}

.two .form-group input,
.two .form-group select {
  background-color: #ffffff;
  color: #072a46;
}

.form-group button {
  outline: none;
  background: #072a46;
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.two .form-group button {
  background: #ffffff;
  color: #072a46;
}

button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.form-group:has(.form-remember) {
  align-items: center;
}

.form-group .form-remember {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
}

.form-group .form-remember input[type="checkbox"] {
  transform: scale(0.8);
  display: inline-block;
  width: auto;
  margin: -1px 3px 0 0;
}

.form-group .form-recovery {
  color: #072a46;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .form-group .form-recovery {
    text-align: right;
    letter-spacing: 0.15em;
    text-transform: capitalize;
    font-size: 11px;
  }
}

.form-panel {
  padding: 40px calc(5% + 60px) 40px 60px;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: 425px) {
  .form-panel {
    padding: 40px calc(5% + 20px) 40px 20px;
  }
}

@media screen and (max-width: 430px) {
  .form-panel.one:has(.confirm-form) {
    padding: 40px calc(5% + 30px) 40px 30px;
  }
}

.form-panel.one::before {
  content: "";
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.nav-clicker {
  display: flex;
  justify-content: center;
  width: 25px;
  height: 100%;
  background: #072a46;
  color: #072a46;
  position: absolute;
  float: right;
  position: sticky;
  top: 0;
  right: 0;
  padding-top: 30%;
  cursor: pointer;
  z-index: 1;
  transition: width 0.2s ease-in-out;
}

.nav-clicker:hover {
  width: 30px;
}

.nav-clicker span {
  position: sticky;
  top: 30%;
  width: 2px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 3px;
  transition: background-color 0.2s ease-in-out;
}

.nav-clicker:hover span {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-panel.one.hidden {
  height: 100%;
}

.form-panel.one.hidden:before {
  display: block;
  opacity: 1;
  visibility: visible;
}

.form-panel.two {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 100%;
  background: #072a46;
  width: 100%;
  min-height: 100%;
  padding: 40px calc(10% + 60px) 40px 60px;
  transition: 0.3s ease;
  cursor: pointer;
  transition: left 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-panel.two.active {
  left: 10%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.form-header {
  margin: 0 0 20px;
}

.form-header h1 {
  padding: 4px 0;
  color: #072a46;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.two .form-header h1 {
  position: relative;
  z-index: 40;
  color: #ffffff;
}

.form-panel.one > div {
  position: relative;
  z-index: 2;
}

.form-panel .ruc-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  z-index: 1;
  pointer-events: none;
}

.form-panel .ruc-logo img {
  width: 180px;
  animation: rotate 5s linear 2s infinite;
}

@keyframes rotate {
  to {
    transform: rotateY(360deg);
  }
}

form .imgwrap {
  border-radius: 5px;
  overflow: hidden;
  width: 150px;
  height: 150px;
  box-shadow: 0 0 4px #072a46;
  padding: 2px;
  margin: auto;
}

form .imgwrap > img {
  width: 146px;
  height: 146px;
  border-radius: 5px;
  object-fit: cover;
  object-position: top center;
  pointer-events: none;
}

/* View Student Section */
.form-text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  border-radius: 5px 0 5px 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(7, 42, 70, 0.3);
  padding: 5px;
}

.form-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
  margin: 0;
  color: #072a46;
  text-align: center;
  font-weight: 500;
}

.form-text strong {
  font-size: 1.3em;
}

/* Edit Student Section */
.edit-group {
  color: #072a46;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(7, 42, 70, 0.3);
  padding: 5px;
  margin-bottom: 15px;
}

.edit-group .edit-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
}

.edit-details-text {
  padding: 5px;
}

@media screen and (max-width: 527px) {
  .edit-details-text {
    padding-top: 10px;
    text-align: center;
  }

  .edit-details-text p {
    margin: 3px 0;
  }
}

/* Delete Student Section */
.delete-message {
  margin: 10px 0;
  color: #072a46;
  font-size: 1.1em;
}

.delete-message p {
  margin-bottom: 0;
}

.delete-message i {
  display: inline-block;
  color: darkred;
  font-size: 0.9em;
  line-height: 14px;
}

/* Statistics Section */
.form-panel.one:has(.stats-pie) .form-header {
  margin-bottom: 0;
}

.stats-pie {
  height: 290px;
  display: flex;
  justify-content: center;
}

.stats-breakdown {
  position: absolute;
  bottom: 0;
  left: 0;
}

.stats-breakdown button {
  border: none;
  width: 40px;
  padding: 4px;
  opacity: 0.8;
  padding-right: 0;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.stats-breakdown img {
  width: 100%;
  pointer-events: none;
}

.stats-breakdown .average-data {
  display: flex;
  flex-direction: column;
  padding: 8px;
  max-width: 80vh;
  border-radius: 5px;
  font-size: 0.8em;
  color: rgb(51, 51, 51);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 45px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.stats-breakdown .average-label:hover + .average-data,
.stats-breakdown .average-label:focus + .average-data,
.stats-breakdown .average-data:hover {
  opacity: 1;
  pointer-events: initial;
}

.stats-breakdown .average-data > span > span {
  color: rgb(0, 226, 114);
}

.stats-breakdown .average-data > span:nth-child(odd) > span {
  color: rgb(84, 79, 197);
}

/* Attedances Section */
.search-input {
  position: relative;
  margin-top: -10px;
}

.search-input svg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 1.1em;
  cursor: pointer;
}

.search-input input {
  padding-left: 40px;
}

.attendances-wrapper {
  height: 250px;
  overflow-y: auto;
  margin: auto;
}

.attendances-wrapper::-webkit-scrollbar {
  width: 3px;
}

.attendances-wrapper > a,
.attendances-wrapper > span,
.attendance-download {
  display: inline-block;
  width: 52px;
  color: #072a46;
  border: 1px solid transparent;
  text-align: center;
  margin: 3px;
  padding: 5px 2px;
  padding-bottom: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.attendances-wrapper > a:hover,
.attendances-wrapper > a:focus,
.attendances-wrapper > span:hover,
.attendance-download:hover {
  background-color: rgba(7, 42, 70, 0.2);
  border: 1px solid #072a46;
  color: white;
}

.attendance-download.active {
  height: 70px;
  background-color: rgba(144, 225, 141, 0.4);
  border: 1px solid #90e18d;
  color: white;
}

.attendances-wrapper img,
.attendance-download img {
  pointer-events: none;
  width: 40px;
}

.attendances-wrapper label,
.attendance-download label {
  display: inline-block;
  line-height: 10px;
  cursor: pointer;
  font-size: 0.75em;
}

.stats-pie.attendance-pie {
  height: 240px;
}

.attendance-download {
  width: 60px;
}

.attendance-download img {
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(7, 42, 70, 0.3);
}

.attendance-download label {
  line-height: 14px;
  margin-top: 5px;
}

.special-link {
  text-align: center;
}

.special-link a,
.special-link label {
  text-decoration: none;
  color: #072a46;
  cursor: pointer;
}

.special-link a:hover,
.special-link label:hover {
  text-decoration: underline;
}

.special-link svg {
  margin-top: -2px;
}

.search-special-box {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  padding-bottom: 25px;
}

.form-panel.one:has(.search-special-box) {
  min-height: 300px;
}

/* ================================================================================================

Navbar Section

================================================================================================ */
.outlet:has(.form-panel.two.active .nav-sec) {
  height: 380px;
}

.form-panel.two:has(.nav-sec) {
  flex-direction: row;
}

.nav-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 10px 5px;
  border-radius: 10px 0 10px 0;
  position: relative;
}

.nav-sec .absolute-center,
.nav-sec .menu .nav-btn.trigger .line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.nav-sec .menu {
  width: 5em;
  height: 5em;
  position: relative;
}

@media screen and (max-width: 530px) {
  .outlet:has(.form-panel.two.active .nav-sec) {
    height: 320px;
  }

  .nav-sec .menu {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 420px) {
  .outlet:has(.form-panel.two.active .nav-sec) {
    height: 280px;
  }

  .nav-sec .menu {
    transform: scale(0.6);
  }
}

@media screen and (max-width: 375px) {
  .outlet:has(.form-panel.two.active .nav-sec) .form-toggle {
    right: 15%;
  }

  .form-panel.two {
    padding: 40px calc(10% + 40px) 40px 40px;
  }
}

.nav-sec .menu .nav-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -10;
  cursor: pointer;
  transition: opacity 1s, z-index 0.3s, transform 1s;
  transform: translateX(0);
}

.nav-sec .menu .nav-btn.trigger {
  opacity: 1;
  z-index: 6;
  cursor: pointer;
  transition: transform 0.3s;
}

.nav-sec .menu .nav-btn.trigger:hover {
  transform: scale(1.2);
}

.nav-sec .menu .nav-btn.trigger .line {
  width: 60%;
  height: 6px;
  background: #000;
  border-radius: 6px;
  transition: background-color 0.3s, height 0.3s, top 0.3s;
}

.nav-sec .menu .nav-btn.trigger .line:before,
.nav-sec .menu .nav-btn.trigger .line:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background: #000;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-sec .menu .nav-btn.trigger .line:before {
  top: -12px;
  transform-origin: 15% 100%;
}

.nav-sec .menu .nav-btn.trigger .line:after {
  top: 12px;
  transform-origin: 25% 30%;
}

.nav-sec .menu .rotater {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
}

.nav-sec .menu .nav-btn.nav-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-sec .menu.active .nav-btn-icon {
  opacity: 1;
  z-index: 10;
}

.nav-sec .menu.active .nav-btn-icon > span {
  display: inline-block;
}

.nav-sec .menu.active .trigger .line {
  height: 0px;
  top: 45%;
}

.nav-sec .menu.active .trigger .line:before {
  transform: rotate(45deg);
  width: 110%;
}

.nav-sec .menu.active .trigger .line:after {
  transform: rotate(-45deg);
  width: 110%;
  top: 9px;
}

.nav-sec .rotater:nth-child(1) {
  transform: rotate(-22.5deg);
}

.nav-sec .menu.active .rotater:nth-child(1) .nav-btn-icon {
  transform: translateY(-8em) rotate(22.5deg);
}

.nav-sec .rotater:nth-child(2) {
  transform: rotate(22.5deg);
}

.nav-sec .menu.active .rotater:nth-child(2) .nav-btn-icon {
  transform: translateY(-8em) rotate(-22.5deg);
}

.nav-sec .rotater:nth-child(3) {
  transform: rotate(67.5deg);
}

.nav-sec .menu.active .rotater:nth-child(3) .nav-btn-icon {
  transform: translateY(-8em) rotate(-67.5deg);
}

.nav-sec .rotater:nth-child(4) {
  transform: rotate(112.5deg);
}

.nav-sec .menu.active .rotater:nth-child(4) .nav-btn-icon {
  transform: translateY(-8em) rotate(-112.5deg);
}

.nav-sec .rotater:nth-child(5) {
  transform: rotate(157.5deg);
}

.nav-sec .menu.active .rotater:nth-child(5) .nav-btn-icon {
  transform: translateY(-8em) rotate(-157.5deg);
}

.nav-sec .rotater:nth-child(6) {
  transform: rotate(202.5deg);
}

.nav-sec .menu.active .rotater:nth-child(6) .nav-btn-icon {
  transform: translateY(-8em) rotate(-202.5deg);
}

.nav-sec .rotater:nth-child(7) {
  transform: rotate(247.5deg);
}

.nav-sec .menu.active .rotater:nth-child(7) .nav-btn-icon {
  transform: translateY(-8em) rotate(-247.5deg);
}

.nav-sec .rotater:nth-child(8) {
  transform: rotate(292.5deg);
}

.nav-sec .menu.active .rotater:nth-child(8) .nav-btn-icon {
  transform: translateY(-8em) rotate(-292.5deg);
}

.nav-sec a {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #072a46;
  text-decoration: none;
}

.nav-sec a label {
  font-size: 10px;
  margin-top: -25px;
  color: transparent;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.nav-sec a:hover label,
.nav-sec a.active label {
  margin-top: -5px;
  color: #072a46;
}

.nav-sec a.active label {
  font-size: 11px;
  font-weight: bolder;
}

.prev-next {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  padding: 0 10px;
}

.prev-next.hide a {
  pointer-events: none;
}

.prev-next img {
  width: 30px;
  transition: transform 0.3s ease-in-out;
}

.prev-next.hide img {
  transform: scale(0);
}

.prev-next.admin-link {
  top: initial;
  bottom: 0;
  padding: 0 5px;
}

.nav-sec img {
  pointer-events: none;
}

/* ================================================================================================

Footer Section

================================================================================================ */
footer {
  padding: 0 5px;
}

footer * {
  color: white;
}

@media screen and (max-width: 425px) {
  footer {
    font-size: 0.8em;
  }
}

/* =============================================================================================

Status Message

================================================================================================ */
.status-wrapper {
  width: auto;
  min-width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 5px 0;
}

.status-message {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 10px 20px;
  padding-right: 35px;
  background-color: #62ddf9;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
}

.status-message.success {
  background-color: #90e18d;
}

.status-message.failed {
  background-color: red;
}

.status-close {
  position: absolute;
  right: 10px;
  color: white;
  cursor: pointer;
}

/* =============================================================================================

Sweet Alert

================================================================================================ */
.swal2-show {
  border: 3px solid #072a46 !important;
  max-width: 90vw !important;
}
.swal2-cancel {
  background-color: #ffc436 !important;
  color: white !important;
}
.swal2-text {
  text-align: center;
}

.swal2-confirm {
  background-color: #072a46 !important;
  color: white !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

/* =============================================================================================

File Upload

================================================================================================ */
.upload-status {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Georgia, 'Times New Roman', Times, serif";
}

#upload-progress,
#upload-progress-bar {
  width: 300px;
  max-width: 95%;
  height: 20px;
  background-color: rgba(7, 42, 70, 0.5);
  border-radius: 25px;
  margin: 10px 0 3px 0;
  position: relative;
}

#upload-progress > label {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#upload-progress-bar {
  max-width: 100%;
  margin: 0;
  background-color: green;
  transition: width 0.3s ease-in-out;
}

.loader-item {
  background: linear-gradient(to top right, #dd4875 0%, #7f3b83 100%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 2s shine linear infinite;
  color: transparent !important;
  cursor: progress;
  position: relative;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* =============================================================================================

Special Select

================================================================================================ */
.special-select-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.special-select-wrapper::before {
  content: "";
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(../public/images/chapel.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
}

.special-select-wrapper .selection-box {
  width: 100%;
  max-height: 80px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.special-select-wrapper .selection-box > span {
  width: 63px;
  height: 18px;
  background-color: #072a46;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 20px;
  font-size: 7px;
  margin: 2px;
  transition: background-color 0.2s ease-in-out;
  cursor: default;
}

.special-select-wrapper.departments-select .selection-box > span {
  width: 78px;
}

.special-select-wrapper .selection-box > span:hover {
  background-color: #ffc436;
}

.special-select-wrapper .selection-box > span > svg {
  font-size: 9px;
  cursor: pointer;
}

.special-select-wrapper .special-select {
  width: 100%;
  max-width: 100%;
  height: 300px;
  padding: 2px 5px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow-y: auto;
  transition: height 0.2s ease-in-out;
}

.special-select-wrapper .special-select-section,
.departments-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: whitesmoke;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
}

.special-select-wrapper .special-select-section:hover {
  box-shadow: 0 0 3px #072a46;
  color: #072a46;
}

.special-select-wrapper
  .special-select-section:has(input[type="checkbox"]:checked) {
  box-shadow: 0 0 3px green;
}

.special-select-wrapper .special-select-section > span {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.special-select-wrapper
  .special-select-section:has(input[type="checkbox"]:checked)
  > strong {
  color: green;
}

.special-select-wrapper .special-select-section > span > span {
  display: flex;
  align-items: center;
}

.special-select-wrapper
  .special-select-section
  > span
  > span:has(input[type="checkbox"]:checked) {
  color: green;
}

.special-select-wrapper
  .special-select-section
  > span
  > span
  > input[type="checkbox"] {
  transform: scale(0.8);
  margin-right: 2px;
  cursor: pointer;
}

.special-select-wrapper
  .special-select-section
  > span
  > span:has(input[type="checkbox"]:checked) {
  font-weight: bolder;
}

.special-select-wrapper .special-button {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.special-select-wrapper .special-close,
.special-select-wrapper .special-clear {
  width: 40px;
  height: 40px;
  background-color: #90e18d;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  cursor: pointer;
  margin: 10px 0;
}

.special-select-wrapper .special-clear {
  background-color: red;
}

@media screen and (max-width: 425px) {
  .special-select-wrapper .special-button {
    left: initial;
    right: -30px;
  }

  .special-select-wrapper .special-close,
  .special-select-wrapper .special-clear {
    width: 25px;
    height: 25px;
    font-size: 0.9em;
  }
}

/* =============================================================================================

Password Note

================================================================================================ */
.password-note {
  height: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  color: #072a46;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0.3s linear, height 0.3s linear, margin 0.3s linear;
}

.password-input {
  letter-spacing: 5px;
}

.form-group:has(input.password-input:focus) ~ .form-group .password-note {
  height: 100%;
  transform: scale(1);
}

.form-group:has(.password-note) {
  margin-bottom: 0;
}

.password-note ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style-position: inside;
  font-size: 0.8em;
}

.password-note ul li {
  width: 50%;
  color: red;
  list-style-type: "×";
}

.password-note ul li.complete {
  list-style-type: "\2713";
  color: green;
}

.password-note ul li span {
  color: #072a46;
}

#strength,
#strength-bar {
  height: 3px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  margin-bottom: 5px;
}

#strength-bar {
  background-color: green;
  transition: width 0.3s ease-in-out;
}

/* =============================================================================================

Request Token

================================================================================================ */
.request-token {
  width: 100%;
  backdrop-filter: blur(5px);
}

.request-token .status-message {
  color: #072a46;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bolder;
}

.request-token button {
  width: auto;
  padding: 5px 20px;
}

.student-upload-text {
  width: 100%;
  background-color: #d8d9e6;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  color: #072a46;
  margin-bottom: 15px;
  position: relative;
}

.student-upload-text p:last-child {
  margin-bottom: 0;
}

.student-upload-copy {
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 1.2em;
  cursor: pointer;
  color: #072a46;
}

.student-upload-view {
  right: 35px;
}

.truncate-text {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flicker {
  animation: flicker infinite 2s linear;
}

@keyframes flicker {
  from {
    color: #072a46;
  }
  to {
    color: darkred;
  }
}

.student-upload-viewer {
  width: 100vw;
  height: 100vh;
  padding: 10px;
  padding-top: 40px;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  color: #072a46;
  z-index: 5;
}

.student-upload-viewer::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d8d9e6;
  opacity: 0.8;
  z-index: -1;
}

.student-upload-viewer > div {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.student-upload-viewer > svg {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  cursor: pointer;
  color: red;
  transition: color 0.3s ease-in-out;
}

.student-upload-viewer > svg:hover {
  color: #072a46;
}

.App:has(.student-upload-viewer) footer {
  z-index: initial !important;
}

/* =============================================================================================

Departments

================================================================================================ */
.departments-wrapper {
  overflow-y: auto;
}

.departments-wrapper > div {
  width: 100%;
  color: #072a46;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 5px 8px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out;
}

.departments-wrapper > div:hover {
  box-shadow: 0 0 5px rgba(7, 42, 70, 0.5);
}

.departments-wrapper > div > span {
  display: inline-block;
  width: calc(100% - 40px);
  height: 100%;
  /* background-color: red; */
  overflow-x: auto;
  white-space: nowrap;
}

.departments-wrapper > div > span::-webkit-scrollbar {
  display: none;
}

.departments-wrapper > div > a {
  display: inline-block;
  color: inherit;
  margin: 0 8px;
}
