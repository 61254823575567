.outlet:has(.error.offline) {
  height: 300px !important;
}

.form-panel.one:has(.error.offline) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px !important;
  padding: 20px;
  overflow: hidden;
}

.outlet:has(.error.offline) .nav-clicker {
  pointer-events: none;
}

.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #072a46;
  text-align: center;
}

.error.offline {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 4;
}

.error.offline::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  backdrop-filter: blur(8px);
  z-index: -1;
}

.error.offline p {
  font-weight: 400;
  color: white;
  text-shadow: 0 2px 3px #072a46;
}

.error img {
  pointer-events: none;
}

.error .error-btn {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  color: white;
  font-size: 0.9em;
  text-decoration: none;
  background-color: #072a46;
  border: none;
}

.error .error-btn:disabled {
  background-color: gray;
}

.error .error-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error .error-btn span svg {
  margin-right: 5px;
  font-size: 1.2em;
}
